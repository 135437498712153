import React from 'react';
import { Link } from 'react-router-dom';
import "./NavBar.css"

function NavBar() {
    return (
        <nav className="navbar">
            <ul>
                <li><Link to="/masktool">Mask Tool</Link></li>{" "}
                <li><Link to="/decode-time">Decode Time</Link></li>{" "}
                <li><Link to="/base64convert">Image Converter</Link></li>
            </ul>
        </nav>
    );
}

export default NavBar;
