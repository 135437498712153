import logo from './logo.svg';
import './App.css';
import {Routes, Route} from "react-router-dom";
import MaskTool from "./components/MaskTool/MaskTool.js"
import NavBar from './components/NavBar/NavBar.js';
import ImageConverter from './components/ImageConverter/ImageConverter.js';
import DecodeTime from './components/DecodeTime/DecodeTime.js';

function App() {
  return (
    <div className="App">
        <NavBar />
        <Routes>
          <Route exact path="/masktool" element={<MaskTool/>} />
          <Route exact path="/decode-time" element={<DecodeTime/>} />
          <Route exact path="/base64convert" element={<ImageConverter/>} />
        </Routes>
    </div>
  );
}

export default App;
