import React, { useState } from 'react';
import "./MaskTool.css";

/* global BigInt */

const GameConstants = {
    MAX_BUFFSTAT: 32
};

const MaskTool = () => {
    const [hexInput, setHexInput] = useState('');
    const [maxBuffStats, setMaxBuffStats] = useState(GameConstants.MAX_BUFFSTAT);
    const [result, setResult] = useState('');
    const [segmentedString, setSegmentedString] = useState([]);
    const [highlightIndexes, setHighlightIndexes] = useState([]);
    const [ignoreFirstInt, setIgnoreFirstInt] = useState(false);

    const preprocessHexString = (hexString, maxBuffStats) => {
        const cleanedString = hexString.replace(/\s+/g, '');
        const segment = cleanedString.slice(0, maxBuffStats * 8);
        return segment;
    };

    const hexStringToInts = (hexString) => {
        const preprocessedString = preprocessHexString(hexString, maxBuffStats);
        const intStrings = preprocessedString.match(/.{1,8}/g);
        setSegmentedString(intStrings);
        const reversedStrings = intStrings.map(str => str.match(/.{1,2}/g).reverse().join(''));
        const ints = reversedStrings.map(str => BigInt(`0x${str}`));
        return ints;
    };

    const generatePossibleValues = (maxBit) => {
        let values = [];
        for (let i = 0n; i < maxBit; i++) {
            values.push(1n << i);
        }
        return values;
    };

    const findCombinations = (target, possibleValues, currentSum = 0n, currentCombination = [], allCombinations = [], memo = new Set(), foundCombination = { found: false }) => {
        if (foundCombination.found) {
            return;
        }

        const key = `${currentSum}-${currentCombination.length}`;
        if (memo.has(key)) {
            return;
        }

        if (currentSum === target) {
            allCombinations.push([...currentCombination]);
            foundCombination.found = true;
            return;
        }
        memo.add(key);

        for (let i = possibleValues.length - 1; i >= 0; i--) {
            const newSum = currentSum + possibleValues[i];
            if (newSum === target) {
                currentCombination.push(possibleValues[i]);
                allCombinations.push([...currentCombination]);
                currentCombination.pop();
                foundCombination.found = true;
                return;
            } else if (newSum < target) {
                currentCombination.push(possibleValues[i]);
                findCombinations(target, possibleValues, newSum, currentCombination, allCombinations, memo, foundCombination);
                if (foundCombination.found) {
                    return;
                }
                currentCombination.pop();
            }
        }
    };

    const hexSumCombinations = (target) => {
        const possibleValues = generatePossibleValues(32n);
        let allCombinations = [];
        findCombinations(BigInt(target), possibleValues, 0n, [], allCombinations, new Set(), { found: false });
        return allCombinations;
    };

    const findIndexFromMaskAndPosition = (mask, position) => {
        let iindex;
        if (mask === 0x80000000) {
            iindex = 32;
        } else {
            iindex = Math.log2(mask) + 1;
        }

        const index = (maxBuffStats * 32) - ((32 * (position - 1)) + 1) - (iindex - 1);
        return index;
    };

    const handleCalculate = () => {
        if (!hexInput.trim()) {
            setResult('Invalid input');
            return;
        }

        try {
            let ints = hexStringToInts(hexInput);
            if (ignoreFirstInt) {
                ints = ints.slice(1);
                setSegmentedString(prev => prev.slice(1));
            }
            const indexes = [];
            const masks = [];
            const positions = [];

            for (let i = 0; i < ints.length; i++) {
                if (ints[i] !== 0n) {
                    const combinations = hexSumCombinations(ints[i]);
                    combinations.forEach(combination => {
                        combination.forEach(mask => {
                            masks.push(`0x${mask.toString(16).toUpperCase()}`);
                            positions.push(maxBuffStats - i);
                        });
                    });
                    indexes.push(i);
                }
            }

            setHighlightIndexes(indexes);
            if (positions.length > 0) {
                let resultString = masks.map((mask, idx) => {
                    const position = positions[idx];
                    const index = findIndexFromMaskAndPosition(parseInt(mask, 16), position);
                    return `Mask ${mask} : Position ${position} : Index ${index}`;
                }).join('\n');
                setResult(resultString);
            } else {
                setResult('No buffmask found');
            }
        } catch (error) {
            console.error('Error calculating buffmask:', error);
            setResult('Error calculating buffmask');
        }
    };

    return (
        <>
        <h2>Hexadecimal Buffmask Calculator</h2>
        <div className="tool-container">
            <div className="top-tool-container">
                <label>
                    <textarea 
                        className="textarea-fixed"
                        value={hexInput} 
                        onChange={(e) => setHexInput(e.target.value)} 
                        placeholder="Enter hexadecimal string"
                        rows="4"
                        cols="50"
                    />
                </label>
                <div className="input-container">
                    <div className="input">
                        <label>MAX_BUFFSTAT:</label>
                        <input 
                            type="number" 
                            min="0" 
                            value={maxBuffStats} 
                            onChange={(e) => setMaxBuffStats(parseInt(e.target.value, 10))}
                        />
                        <span>{maxBuffStats}</span>
                    </div>
                    <div className="input">
                        <label>
                            v200+:
                        </label>
                        <input 
                            className="ignore-input"
                            type="checkbox" 
                            checked={ignoreFirstInt} 
                            onChange={(e) => setIgnoreFirstInt(e.target.checked)} 
                        />
                    </div>
                </div>
            </div>
            <div className="bottom-tool-container">
                <button className="calculate-button" onClick={handleCalculate}>Calculate</button>
            </div>
        </div>
        <div className="result-container">
        <div id="result">
                <pre>{result}</pre>
            </div>
            <div className="segmented-container">
                {segmentedString.map((segment, index) => (
                    <span
                        key={index}
                        className={`segment ${highlightIndexes.includes(index) ? 'highlight' : ''}`}
                    >
                        {segment}
                    </span>
                ))}
            </div>
        </div>
    </>
    );
};

export default MaskTool;
