import React, { useState } from 'react';


/* global BigInt */

function decodeTime(fakeTimestamp) {
    if (fakeTimestamp === 150842304000000000n) {
        return -1n;
    }
    if (fakeTimestamp === 94354848000000000n) {
        return -2n;
    }
    if (fakeTimestamp === 150841440000000000n) {
        return -3n;
    }
    return (fakeTimestamp - 116444592000000000n) / 10000n;
}

const HexToTimeDecoder = () => {
    const [hexString, setHexString] = useState('');
    const [decodedDateTime, setDecodedDateTime] = useState(null);

    const handleDecode = () => {
        try {
            // Remove spaces and reverse the hex string
            const reversedHex = hexString.replace(/\s+/g, '').match(/.{1,2}/g).reverse().join('');
            // Convert the reversed hex string to a decimal value
            const fakeTimestamp = BigInt('0x' + reversedHex);
            // Decode the time
            const result = decodeTime(fakeTimestamp);

            // Convert the result to a date-time if it is not a special value (-1, -2, -3)
            if (result >= 0) {
                const date = new Date(Number(result));
                setDecodedDateTime(date.toString());
            } else {
                setDecodedDateTime(result.toString()); // Special values handling
            }
        } catch (error) {
            setDecodedDateTime('Invalid hex string');
        }
    };

    return (
        <div>
            <h1>Hex to Date-Time Decoder</h1>
            <input
                type="text"
                value={hexString}
                onChange={(e) => setHexString(e.target.value)}
                placeholder="Enter hex string"
            />
            <button onClick={handleDecode}>Decode</button>
            {decodedDateTime !== null && (
                <div>
                    <h2>Decoded Date-Time: {decodedDateTime}</h2>
                </div>
            )}
        </div>
    );
};

export default HexToTimeDecoder;
