import React, { useState, useEffect } from 'react';
import './ImageConverter.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ImageConverter = () => {
    const [base64Images, setBase64Images] = useState([]);
    const [copied, setCopied] = useState(false); 
    const [combinedBase64Data, setCombinedBase64Data] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const formatBase64Images = async () => {
            setLoading(true);
            let combinedData = '';
            const promises = base64Images.map(async (base64, index) => {
                const dimensions = await getImageDimensions(base64);
                const base64Data = base64.split(",")[1];

                combinedData += `
                    <canvas name="${index}" width="${dimensions.width}" height="${dimensions.height}" basedata="${base64Data}">
                        <vector name="origin" x="0" y="0"/>
                    </canvas>
                `;
            });

            await Promise.all(promises);
            setCombinedBase64Data(combinedData);
            setLoading(false);
        };

        if (base64Images.length > 0) {
            formatBase64Images();
        }
    }, [base64Images]);

    const getImageDimensions = (base64) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                resolve({ width: img.width, height: img.height });
            };
            img.src = base64;
        });
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        const fileArray = Array.from(files);
        convertFilesToBase64(fileArray);
    };

    const convertFilesToBase64 = (files) => {
        setLoading(true);
        const promises = files.map(file => convertToBase64(file));
        Promise.all(promises)
            .then(base64Strings => {
                setBase64Images(base64Strings);
            })
            .catch(error => {
                console.error('Error converting files to base64:', error);
            })
            .finally(() => setLoading(false));
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    return (
        <div className="converter-container">
            <h2>Image Converter to Base64</h2>
            <input
                type="file"
                multiple
                onChange={handleFileChange}
                accept="image/*"
            />
            {loading && <p>Loading...</p>}
            <div className="results">
                {base64Images.length === 0 && !loading && <p>No images selected.</p>}
                {base64Images.length > 0 && base64Images.map((base64, index) => (
                    <div key={index} className="result-item">
                        <img src={base64} alt={`Converted ${index}`} />
                    <textarea value={base64.split(',')[1]} readOnly rows="10" cols="50" />
                    <CopyToClipboard 
                        text={base64}
                        onCopy={() => setCopied(true)}
                    >
                        <button>Copy to Clipboard</button>
                    </CopyToClipboard>
                    {copied && <span style={{color: 'red'}}>Copied!</span>}
                    </div>
                ))}
            </div>
            {base64Images.length > 0 && !loading &&
                <div>
                    <textarea value={combinedBase64Data} readOnly rows="10" cols="50" />
                    <CopyToClipboard 
                        text={combinedBase64Data}
                        onCopy={() => setCopied(true)}
                    >
                        <button>Copy to Clipboard</button>
                    </CopyToClipboard>
                    {copied && <span style={{color: 'red'}}>Copied!</span>}
                </div>
            }
        </div>
    );
};

export default ImageConverter;
